*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.project-container{
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.project-title-container{
    margin-top: 140px;
    margin-bottom: 40px;
}

.project-title{
    display: flex;
    color: #343434;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 800;
}

.project-list-container{
    width: auto;
    height: auto;
}

.project-list{
    list-style-type: none;
}

.project{
    display: flex;
    height: auto;
    width: 90vw;
    justify-content: space-around;
    align-items: center;    
}

.project-description-container{
    display: flex;
    flex-direction: column;
    height: 275px;
    justify-content: space-around;
}
.project-header{
    color: #343434;
}
.project-desc{
    color: #343434;
}

.project-btn-containers{
    display: flex;
    width: 250px;
    justify-content: flex-start;
}

.live-btn{
    display: flex;
    width: 100px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: #343434;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #FFFFFF;
    transition: ease-out 0.3s;
    font-weight: 500;
}
.source-btn{
    display: flex;
    width: 100px;
    height: 40px;
    color: #343434;
    align-items: center;
    justify-content: center;
    border: 1px solid #343434;
    cursor: pointer;
    /* margin-left: 40px; */
    position: relative;
    box-shadow: inset 0 0 0 0 #FFFFFF;
    transition: ease-out 0.3s;
    z-index: 1;
}
.live-btn:hover{
    box-shadow: inset 100px 0 0 0 #e9e6e6;
    color: #343434;
}
.source-btn:hover{
    color: #FFFFFF
}
.source-btn::before{
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color: #343434;
}
.source-btn:hover::before{
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -1;
}
.project-img{
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
}
.project-img > img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

@media screen and (min-width: 768px) {
    .project{
        width: 800px;
    }
    .project-title{
        font-size: 52px;
    }
    .project-description-container{
        display: flex;
        flex-direction: column;
        height: 230px;
        width: 400px;
        justify-content: space-around;
    }
    .project-btn-containers{
        width: 400px;
    }
    .project-img > img {
        width: 100px;
        height: 100px;
    }
}