.nav_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 8vh;
    background-color: #343434;
    position: fixed;
    z-index: 1;
}

.logo_container{
    width: auto;
    height: auto;
}
.logo{
    color:rgb(233, 231, 231);
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 0.2px;
    cursor: pointer;
}
.list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    width: 50%;
}
.list_item{
    display: inline-block;
    color:rgb(233, 231, 231);
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0.2px;
    cursor: pointer;
}
.list_item:after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
  }
.list_item:hover:after {
    width: 100%;
    background: rgb(233, 231, 231);
}
.burger{
    cursor: pointer;
    display: none;
}
.burger > div{
    width: 21px;
    height: 2px;
    background-color:rgb(233, 231, 231);
    margin: 5px;
    transition: all 0.3s ease;
}
@media screen and (max-width: 768px) {
    .list{
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
        width: 70%;
        position: absolute;
        right: 0;
        top: 8vh;
        height: 92vh;
        background-color: #343434;
        display: flex;
        flex-direction: column; 
        justify-content: flex-start;
        align-items: center;
        transform: translateX(100%);
        transition: transform 1s ease-in;
    }
    .list_item{
        display: flex;
        color:rgb(233, 231, 231);
        font-size: 16px;
        font-weight: 800;
        letter-spacing: 0.2px;
        opacity: 0;
        width: 100%;
        height: 100px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        align-items: center;
        border-bottom: 1px solid rgb(5, 5, 5);
    }
    .list_item:first-child{
        border-top: 1px solid rgb(5, 5, 5);
    }
    .list_item:after {
        content: none;
      }
    .burger{
        display: block;
    }
    .toggle .burger_line1{
        transform: rotate(-45deg) translate(-5px,5px);
    }
    .toggle .burger_line2{
        opacity: 0;
    }
    .toggle .burger_line3{
        transform: rotate(45deg) translate(-5px,-5px);
    }
    
    .nav_active{
        transform: translateX(0);
    }
    
    @keyframes navLinkFade {
        from {
            opacity: 0;
            transform: translateX(50px);
        }
        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }
    
}