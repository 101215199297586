*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
    color: #f8f8ff;
  }
.home-container{
    display: flex;
    flex-direction: column;
    width: 70vw;
    height: 70vh;
}
.info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 80px;
    height: 50%;
}

.info-container > h3{
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
}
.info-container > h1 {
    font-size: 90px;
    letter-spacing: 1px;
    font-weight: 800;
}
.info-container > p {
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
    .info-container > h3{
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 1px;
    }
    .info-container > h1 {
        font-size: 60px;
        letter-spacing: 1px;
        font-weight: 800;
    }
    .info-container > p {
        font-size: 24px;
        font-weight: 400;
    }
}