*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}

body {
  overflow-x:hidden;
} 

.home, .contact{
  background-color: #E5E5E5
}
.home{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./img/bg-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
}

.about{
  height: auto;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.exp{
  height: auto;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-color: #E5E5E5;
}
.projects{
  height: auto;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.contact{
  height: auto;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-color: #E5E5E5;
}
.img2{
  height: 20vh;
  width: 100vw;
  background-image: url('./img/img-2.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.img3{
  height: 20vh;
  width: 100vw;
  background-image: url('./img/img3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
@media screen and (max-width: 768px) {
  .home{
    background-attachment: scroll;
  }
  .img2{
    display: none;
  }
  .img3{
    display: none;
  }
}
